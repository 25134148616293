import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Body from './queryOrMutationBody';

const graphQLQuery = graphql`
  query Queries {
    allSolveGraphQlQuery {
      edges {
        node {
          name
          description
          args {
            name
            description
            type {
              kind
              name
              ofType {
                kind
                name
                ofType {
                  kind
                  name
                }
              }
            }
          }
          type {
            kind
            name
            ofType {
              kind
              name
            }
          }
        }
      }
    }
  }
`;

const Query = ({ name }) => {
  return (
    <StaticQuery
      query={graphQLQuery}
      render={data => {
        return (
          <Body
            name={name}
            edges={data.allSolveGraphQlQuery.edges}
            heading='Query'
            permalinkPrefix='query'
          />
        );
      }}
    />
  );
};

export default Query;
