import React, { useState } from 'react';
import TypeDetails from '.';
import ToggleButton from './toggleButton';

// React component for rendering any child information and the view more/less button
// + associated state. Indents on the x axis each time it is rendered for visual
// relationship representation
const SubFields = ({ fields }) => {
  const [isOpen, setIsOpen] = useState(false);
  if (!fields || !fields.length) {
    return null;
  }

  if (!isOpen) {
    return (
      <ToggleButton onClick={() => setIsOpen(true)}>
        + view more information
      </ToggleButton>
    );
  }

  return (
    <>
      <ToggleButton onClick={() => setIsOpen(false)}>
        - view less information
      </ToggleButton>
      <div className='mx-4'>
        {fields.map(field => (
          <div key={field.name} className='mb-4 pb-4 border-t border-gray-800'>
            <TypeDetails
              prefix={field.name}
              description={field.description}
              type={field.type}
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default SubFields;
