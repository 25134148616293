import React from 'react';

const generateTypeText = type => {
  if (!type) {
    // If we get an empty `type` here then make sure we don't crash by simply
    // rendering `unknown`
    return 'Unknown';
  }
  switch (type.kind) {
    case 'NON_NULL':
      return `${generateTypeText(type.ofType)}!`;
    case 'LIST':
      return `[${generateTypeText(type.ofType)}]`;
    default:
      return type.name;
  }
};

const Type = ({ type }) => {
  const text = generateTypeText(type);

  return <code>{text}</code>;
};

export default Type;
