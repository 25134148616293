import React from 'react';
import TypeDetails from './typeDetails';
import Arguments from './arguments';
import { H3, H4 } from '../permalink';

const QueryOrMutationBody = ({ name, heading, permalinkPrefix, edges }) => {
  const queryOrMutationEdge = edges.find(n => {
    return n.node.name === name;
  });

  if (!queryOrMutationEdge) {
    throw new Error(`Cannot render ${name}. Not found in the GraphQL Schema`);
  }

  const { node } = queryOrMutationEdge;
  const id = `${permalinkPrefix}_${node.name.replace(' ', '_')}`;

  return (
    <>
      <H3 id={id}>
        {heading}: {node.name}
      </H3>
      {node.description && <p>{node.description}</p>}
      <H4 id={`${id}_return_type`}>Return Type</H4>
      <div className='mb-4 pt-4 border-t border-gray-800'>
        <TypeDetails type={node.type} />
      </div>
      <Arguments node={node} id={id} />
    </>
  );
};

export default QueryOrMutationBody;
