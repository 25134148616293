import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Body from './queryOrMutationBody';

const graphQLQuery = graphql`
  query Mutations {
    allSolveGraphQlMutation {
      edges {
        node {
          name
          description
          args {
            name
            description
            type {
              kind
              ofType {
                kind
                name
              }
              name
            }
          }
          type {
            kind
            name
          }
        }
      }
    }
  }
`;

const Mutation = ({ name }) => {
  return (
    <StaticQuery
      query={graphQLQuery}
      render={data => {
        return (
          <Body
            name={name}
            edges={data.allSolveGraphQlMutation.edges}
            heading='Mutation'
            permalinkPrefix='mutation'
          />
        );
      }}
    />
  );
};

export default Mutation;
