import React from 'react';

// React component for rendering the the button to toggle the view state of more
// type information
const ToggleButton = ({ children, onClick }) => {
  return (
    <button
      className='bg-gray-200 cursor-pointer my-4 text-sm text-gray-900 bg-gray-100 rounded-full border border-gray-500 hover:text-gray-900 hover:border-gray'
      onClick={onClick}
    >
      <span className='block my-1 mx-2'>{children}</span>
    </button>
  );
};

export default ToggleButton;
