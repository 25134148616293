import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Type from '../type';
import Enum from './enum';
import SubFields from './subFields';
import { hasSubFieldInfo, getDeepestType } from './utils';

// Look at the size of this thing! It gets all the types and their subtypes and
// potentially any other type details from there
const graphQLQuery = graphql`
  query typeQueries {
    allSolveGraphQlType {
      edges {
        node {
          name
          description
          args {
            name
            type {
              kind
              name
            }
            defaultValue
          }
          type {
            kind
            name
            ofType {
              kind
              name
            }
          }
          deprecationReason
          isDeprecated
          key
          kind
          subFields {
            args {
              type {
                kind
                name
              }
              name
              defaultValue
            }
            name
            isDeprecated
            description
            deprecationReason
            type {
              kind
              name
              ofType {
                kind
                name
                ofType {
                  kind
                  name
                  ofType {
                    kind
                    name
                  }
                }
              }
            }
          }
          enumValues {
            description
            isDeprecated
            name
          }
        }
      }
    }
  }
`;

// React component for helping with the description of types. There is a small amount
// of logic here to ensure we don't render default type descriptions for things like
// an `Int` or a `String` when we should be rendering the description that comes from
// Absinthe from our developers
const Description = ({ description, deepestType, node }) => {
  if (description) {
    return <p>{description}</p>;
  }

  if (!hasSubFieldInfo(deepestType.kind)) {
    // Doesn't have a description and also is not of a type that has it's own
    // description and subfields to render. Do not render anything here
    return null;
  }

  return node.description ? <p>{node.description}</p> : null;
};

// React component for rendering types, descriptions, and any nested information
const TypeDetails = ({ prefix, description, type }) => {
  const { name } = getDeepestType(type);
  return (
    <StaticQuery
      query={graphQLQuery}
      render={data => {
        const type = data.allSolveGraphQlType.edges.find(n => {
          return n.node.name === name;
        });

        if (!type) {
          throw new Error(`Cannot render type ${name}. Not found.`);
        }

        const { node } = type;
        const deepestType = getDeepestType(node);

        return (
          <>
            <p>
              {prefix ? `${prefix} ` : ''}
              <Type type={node} />
            </p>
            <Description
              description={description}
              deepestType={deepestType}
              node={node}
            />
            {deepestType.kind === 'ENUM' && (
              <Enum enumValues={node.enumValues} />
            )}
            {hasSubFieldInfo(deepestType.kind) && (
              <SubFields fields={node.subFields} />
            )}
          </>
        );
      }}
    />
  );
};

export default TypeDetails;
