import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Type from '../type';
import { TypeDetailsEnumOnly } from './enum';
import { getDeepestType } from './utils';

const graphQLQuery = graphql`
  query argQueries {
    allSolveGraphQlArgument {
      edges {
        node {
          name
          key
          description
          args {
            description
            name
            type {
              kind
              name
              ofType {
                kind
                name
                ofType {
                  kind
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;

// React component for helping with the description of types. There is a small amount
// of logic here to ensure we don't render default type descriptions for things like
// an `Int` or a `String` when we should be rendering the description that comes from
// Absinthe from our developers
const Description = ({ description, node }) => {
  if (description) {
    return <p>{description}</p>;
  }

  return node.description ? <p>{node.description}</p> : null;
};

// React component for rendering arguments, descriptions, and any nested information
const ArgumentDetails = ({ prefix, argument }) => {
  const { name, description, type } = argument;

  return (
    <StaticQuery
      query={graphQLQuery}
      render={data => {
        const arg = data.allSolveGraphQlArgument.edges.find(n => {
          return n.node.name === prefix;
        });

        if (!arg) {
          throw new Error(`Cannot render argument ${name}. Not found.`);
        }

        const { node } = arg;
        const deepestType = getDeepestType(node);

        return (
          <>
            <p>
              {name} <Type type={argument.type} />
            </p>
            <Description
              description={description}
              deepestType={deepestType}
              node={node}
            />
            {type.kind === 'ENUM' && <TypeDetailsEnumOnly name={type.name} />}
          </>
        );
      }}
    />
  );
};

export default ArgumentDetails;
