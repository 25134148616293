import React from 'react';
import ArgDetails from './typeDetails/arguments';
import { H4 } from '../permalink';

const Arguments = ({ node }) => {
  if (!node.args || !node.args.length) {
    // Don't render anything if there are no arguments
    return null;
  }

  return (
    <>
      <H4 id={`${node.id}_input_arguments`}>Input arguments</H4>
      {node.args.map(arg => {
        return (
          <div key={arg.name} className='mb-4 pb-4 border-t border-gray-800'>
            <ArgDetails prefix={node.name} argument={arg} />
          </div>
        );
      })}
    </>
  );
};

export default Arguments;
