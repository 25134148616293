// Helper for determining whether or not there are other pieces of type information
// nested under a given type
export const isNullOrList = kind => ['NON_NULL', 'LIST'].indexOf(kind) !== -1;

// Helper to determine whether the type has sub field information that we would like
// to display. The GraphQL introspection gives these a kind of `OBJECT` or `INPUT_OBJECT
export const hasSubFieldInfo = kind =>
  ['OBJECT', 'INPUT_OBJECT'].indexOf(kind) !== -1;

// The structure of a type that comes via introspection can be a type with an `ofType`
// object, which can also have a `ofType` object and so forward. Generally, it's the
// type that is nested the deepest that we care about when it comes to docs (the rest
// is just whether it's nullable or whether it's a list etc)
export const getDeepestType = type => {
  if (isNullOrList(type.kind)) {
    return getDeepestType(type.ofType);
  }

  return type;
};
