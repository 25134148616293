import React from 'react';
import cn from 'classnames';

import Warning from '../../../static/svg/alert-triangle.svg';
import Info from '../../../static/svg/information-circle.svg';

const CLASSES = {
  warning: {
    icon: cn('text-orange-400'),
    block: cn('border-orange-400')
  },
  info: {
    icon: cn('text-blue-400'),
    block: cn('border-blue-400')
  },
  default: {
    icon: cn('text-blue-400'),
    block: cn('border-blue-400')
  }
};

export default function Note({ children, type = 'warning' }) {
  if (typeof children === typeof '') {
    throw new Error(
      `The 'Note' tag with the following contents has not been rendered using Markdown. ` +
        `Add at least 1 empty line between the content and the enclosing tags to fix this error` +
        `(so opening tag, empty line, content, empty line, closing tag). Content: "${children}"`
    );
  }
  const classes = CLASSES[type] || CLASSES.default;
  return (
    <blockquote>
      <div className={cn('bq-note', `bq-note-${type}`, classes.block)}>
        <Icon
          type={type}
          className={cn('w-4 h-4 bq-note-icon', classes.icon)}
        />
        {children}
      </div>
    </blockquote>
  );
}

export const Icon = ({ type, ...props }) => {
  switch (type) {
    case 'warning':
      return <Warning {...props} />;
    default:
      return <Info {...props} />;
  }
};
