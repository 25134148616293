import React, { useState } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import ToggleButton from './toggleButton';

const graphQLQuery = graphql`
  query enumTypeQueries {
    allSolveGraphQlType {
      edges {
        node {
          name
          description
          args {
            name
            type {
              kind
              name
            }
            defaultValue
          }
          type {
            kind
            name
            ofType {
              kind
              name
            }
          }
          deprecationReason
          isDeprecated
          key
          kind
          subFields {
            args {
              type {
                kind
                name
              }
              name
              defaultValue
            }
            name
            isDeprecated
            description
            deprecationReason
            type {
              kind
              name
              ofType {
                kind
                name
                ofType {
                  kind
                  name
                  ofType {
                    kind
                    name
                  }
                }
              }
            }
          }
          enumValues {
            description
            isDeprecated
            name
          }
        }
      }
    }
  }
`;

// React component for rendering enums as a list. Only renders the first 5 or else
// it can get might long (like currencies, for example)
const Enum = ({ enumValues }) => {
  const [isOpen, setIsOpen] = useState(false);
  const firstFiveEnums = enumValues.slice(0, 5);
  const hasMoreEnums = enumValues.length > 5;

  let mapOver = firstFiveEnums;
  if (hasMoreEnums && isOpen) {
    // We have more to view and the toggle is open. Render all
    mapOver = enumValues;
  }

  let viewMoreButton = null;
  if (hasMoreEnums && !isOpen) {
    viewMoreButton = (
      <ToggleButton onClick={() => setIsOpen(true)}>
        + view all options ({enumValues.length - 5} more)
      </ToggleButton>
    );
  } else if (hasMoreEnums && isOpen) {
    viewMoreButton = (
      <ToggleButton onClick={() => setIsOpen(false)}>
        + view fewer options
      </ToggleButton>
    );
  }

  return (
    <div className='ml-4'>
      <p>Available options:</p>
      {mapOver.map(e => (
        <div key={e.name}>
          <p>
            <code>{e.name}</code>
            {e.description && ` ${e.description}`}
          </p>
        </div>
      ))}
      {viewMoreButton}
    </div>
  );
};

/**
 * Sometimes we may only want to render out the enum details for a given type without
 * the description and subfields that the `<Type /> component renders
 */
export const TypeDetailsEnumOnly = ({ name }) => {
  return (
    <StaticQuery
      query={graphQLQuery}
      render={data => {
        const type = data.allSolveGraphQlType.edges.find(n => {
          return n.node.name === name;
        });

        if (!type) {
          throw new Error(`Cannot enum details for type ${name}. Not found.`);
        }

        const { node } = type;

        return <Enum enumValues={node.enumValues} />;
      }}
    />
  );
};

export default Enum;
